import { config } from "react-spring"

export const arriveSlowlyFromTopToBottom = {
    to: { 
        transform: "translateY(0%)",
        opacity: 1,
    }, 
    from: { 
        transform: "translateY(-100%)",
        opacity: 0,
    },
    delay: 100, 
    config:  {
        frequency: 2.5,
        damping: 1,
        tension: 300,
        friction: 180,
    }
}

export const fadeInAndScaleIn = {
    to: {
        transform: "scale(1)",
        opacity: 1,
    }, 
    from: { 
        transform: "scale(0.95)",
        opacity: 0,
    },
    delay: 1000, 
    config:  {
        frequency: 2.5,
        damping: 1,
        tension: 300,
        friction: 180,
    }
}

export const arriveSlowlyFromBottomToTop = {
    to: { 
        transform: "translateY(0%)",
        opacity: 1,
        pointerEvents: "all",
    }, 
    from: { 
        transform: "translateY(50%)",
        opacity: 0,
        pointerEvents: "none",
    },
    delay: 1500,
    config:  {
        frequency: 2.5,
        damping: 1,
        tension: 300,
        friction: 180,
    }
}

export const fadeInAfterDelay = {
    to: {
        opacity: 1,
    }, 
    from: { 
        opacity: 0,
    },
    delay: 3000,
    config: config.molasses,
}
