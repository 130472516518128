import { NavLink } from "react-router-dom"
import CodebasedTrueLogo from "./codebased-logo.svg"
import CodebasedWhiteLogo from "./codebased-logo-grey-to-white.svg"

const CodebasedLogo = ({ isWhite = false, className }) => {

    return (
        <NavLink to="/" className="select-none">
            <img src={isWhite ? CodebasedWhiteLogo : CodebasedTrueLogo} alt="Codebased logo" className={`${className} select-none pointer-events-none`} />
        </NavLink>
    )
}

export default CodebasedLogo