import CleanLogo from "./clean.svg"
import TestedLogo from "./tested.svg"
import SecureLogo from "./secure.svg"
import CodeCard from "./code-card.component"
import React from "react"
import { useTranslation } from "react-i18next"

const codeCards = {
    "clean": {
        "title": "Puhdas",
        "bodyMoreContrast": "Puhtaalla koodilla on merkitystä. ",
        "body": "Useimmiten uusia ideoita ominaisuuksista tuppaa syntymään sovelluskehityksen elinkaaren aikana tai jotain säädettävää löytyy. Puhdas koodi on lääke tähän, sillä se johtaa tuotteeseen, joka pystyy nopeasti mukautumaan näihin muuttuviin vaatimuksiin.",
        "image": CleanLogo,
    },
    "tested": {
        "title": "Testattu",
        "bodyMoreContrast": "Huolellisesti testatut sovellukset toimivat paremmin. ",
        "body": "Automaattisen koodin testauksen avulla voimme havaita ja korjata vikoja koko sovelluskehityksen ajan, ennen kuin kukaan käyttäjistäsi kohtaa näitä ongelmia. Lopputuotteesta tulee luotettava ja kiillotettu.",
        "image": TestedLogo
    },
    "secure": {
        "title": "Turvallinen",
        "bodyMoreContrast": "Sovelluksen tulee olla turvallinen. ",
        "body": "Jos näin ei ole, luvattomat käyttäjät voivat saada käsiinsä yksityisiä tietoja tai jopa rikkoa järjestelmiä. Rakennamme ohjelmistoja vahvoilla suojaustoimenpiteillä varmistaaksemme, ettei näin tapahdu sinulle.",
        "image": SecureLogo
    },
}

const Code = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isHeaderShowing = props.showHeader;
    
    return (
        <div id="code" className="text-2xl pt-72 relative top-4 flex sm:flex-row flex-col sm:items-stretch items-center w-full">

                <div className="2xl:w-[42.5%] xl:w-1/3 lg:w-2/4 md:w-3/5 sm:w-1/3 sticky 2xl:pt-44 lg:pt-36 md:pt-32 sm:pt-36 pt-12 top-0 h-fit self-start sm:backdrop-blur-0 backdrop-blur-sm sm:shadow-none shadow-md sm:pb-0 pb-1 w-full overflow-hidden z-10">
                    <h1 id="h1-design" ref={ref}
                     className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 translate-x-8 pointer-events-none'} syne brand-gradient-smaller-text 2xl:text-6xl md:text-4xl lg:text-5xl text-3xl flex-shrink-0 transition-all ease-in duration-200 2xl:ml-12 md:ml-8 relative ml-5 `}>
                        <div className="absolute codebased-brand-ball md:left-0 -left-1 top-1/2 -translate-y-1/2 rounded-full sm:scale-100 scale-75"></div>
                        <span>  {t("home.our-philosophies.code.header")}</span>
                    </h1>
                </div>

                
                <div className="w-[1px] shadow-sm bg-[hsla(291,88%,10%,0.10)] md:mr-6 mr-3"/>

                <div className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 -translate-y-14 -translate-x-14'} flex flex-col gap-36 sm:pt-48 pt-16 w-full transition-all duration-500 ease-in-out pb-12 sm:mr-4 sm:px-0 px-5 max-w-screen-sm`}>
                    
                    <CodeCard 
                        title={codeCards.clean.title} 
                        bodyMoreContrast={codeCards.clean.bodyMoreContrast} 
                        body={codeCards.clean.body} 
                        image={codeCards.clean.image}>
                    </CodeCard>

                    <CodeCard 
                        title={codeCards.secure.title} 
                        bodyMoreContrast={codeCards.secure.bodyMoreContrast} 
                        body={codeCards.secure.body} 
                        image={codeCards.secure.image}>
                    </CodeCard>

                    <CodeCard 
                        title={codeCards.tested.title} 
                        bodyMoreContrast={codeCards.tested.bodyMoreContrast} 
                        body={codeCards.tested.body} 
                        image={codeCards.tested.image}>
                    </CodeCard>

                </div>

            </div>
    )
})

export default Code;