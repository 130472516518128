import { useTranslation } from "react-i18next";

export const hrefs = [
    "#what-we-do",
    "#philosophies",
    "#design",
    "#code",
    "#contact"
];

const InHomePage = ({itemToBoldHref}) => {
    const { t } = useTranslation()

    return (
        <div className="">
            <li>
                <a  href={hrefs[0]} 
                    className={`${itemToBoldHref === hrefs[0] ? 'font-bold' : ''} block tracking-wider transition-all duration-100 py-0.5`}
                    id="bottom-1">{t("sticky-sub-nav.first")}</a>
            </li>
            <li>
                <a  href={hrefs[1]} 
                    className={`${itemToBoldHref === hrefs[1] ? 'font-bold' : ''} block tracking-wider transition-all duration-100 py-0.5`}
                    id="bottom-2">{t("sticky-sub-nav.second.0")}</a>
                <ul className="pl-10">
                    <li>
                        <a  href={hrefs[2]} 
                            className={`${itemToBoldHref === hrefs[2] ? 'font-bold' : ''} block transition-all duration-100 py-0.5`}
                            id="bottom-2.1">{t("sticky-sub-nav.second.1")}</a>
                    </li>
                    <li>
                        <a  href={hrefs[3]}
                            className={`${itemToBoldHref === hrefs[3] ? 'font-bold' : ''} block transition-all duration-100 py-0.5`}
                            id="bottom-2.2">{t("sticky-sub-nav.second.2")}</a>
                    </li>
                </ul>
            </li>
            {/* <li>
                <a href="">Why us?</a>
            </li> */}
            <li>
                <a  href={hrefs[4]} 
                    className={`${itemToBoldHref === hrefs[4] ? 'font-bold' : ''} block tracking-wider transition-all duration-100 py-0.5`}
                    id="bottom-3">{t("sticky-sub-nav.third")}</a>
            </li>
        </div>
    )
}

export default InHomePage;