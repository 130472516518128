import React from "react";
import { useTranslation } from "react-i18next";

const WhatWeDo = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isHeaderShowing = props.showHeader;

    return (
        <div id="what-we-do" className="flex sm:flex-row flex-col mt-40 h-full sm:items-stretch items-center 2xl:max-w-screen-2xl max-w-screen-xl">
            
            <div className="sticky top-0 sm:pt-12 pt-4 h-fit bg-transparent z-10 sm:pr-0 self-start sm:w-fit w-full backdrop-blur-sm sm:shadow-none shadow-md sm:pl-0 pl-3 sm:pb-0 pb-3 bg-blend-lighten">
                <h1 ref={ref}
                className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 translate-y-4 pointer-events-none'} 2xl:text-6xl md:text-4xl lg:text-5xl text-3xl md:font-normal font-medium alegreya-sans transition-all ease-in duration-200`}
                id="h1-what-we-do">
                    {t("home.what-we-do.header")}
                </h1>
            </div>

            <div className="sm:block hidden w-[1px] shadow-sm bg-[hsla(291,88%,10%,0.10)] md:mr-6 mr-3"/>

            <div className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'opacity-0 -translate-x-14 blur-sm'} transition-all duration-500 ease-in-out lg:text-xl md:text-lg text-base sm:pt-64 sm:pb-12 pt-16 alegreya relative top-4 w-full sm:mr-6 sm:px-0 px-5 max-w-screen-lg`}>
                <p>{t("home.what-we-do.p1")} </p>
                <br />
                <p>{t("home.what-we-do.p2")}</p>
            </div>
        </div>
        
    )
})

export default WhatWeDo;