import i18next from "i18next";

import finnishTranslations from "./locales/fi/fi-translations.json";
import englishTranslations from "./locales/en/en-translations.json";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  lng: "fi",
  resources: {
    fi: {
      "fi-translations": finnishTranslations,
    },
    en: {
      "en-translations": englishTranslations,
    },
  },
  ns: ["fi-translations", "en-translations"],
  defaultNS: "fi-translations",
});

export default i18next;
