import Intro from "../../components/intro/intro.component";
import WhatWeDo from "../../components/what-we-do/what-we-do.component";
import Philosophies from "../../components/philosophies/philosophies-component";
import Contact from "../../components/contact/contact-component";
import StickyBottomNav from "../../components/sticky-bottom-nav/sticky-bottom-nav.component";
import React, { useEffect, useState } from "react";
import Code from "../../components/philosophies/code/code.component";
import Design from "../../components/philosophies/design/design.component";

const Home = () => {
    const whatWeDoHeaderRef = React.createRef()
    const [isWhatWeDoHeaderShowing, showWhatWeDoHeaderBasedOn] = useState(false)

    const philosophiesHeaderRef = React.createRef()
    const [isPhilosophiesHeaderShowing, showPhilosophiesHeaderBasedOn] = useState(false)

    const designHeaderRef = React.createRef()
    const [isDesignHeaderShowing, showDesignHeaderBasedOn] = useState(false)
    
    const codeHeaderRef = React.createRef()
    const [isCodeHeaderShowing, showCodeHeaderBasedOn] = useState(false)


    const contactHeaderRef = React.createRef()
    const [isContactHeaderShowing, showContactHeaderBasedOn] = useState(false)

    const stickyBottomNavRef = React.createRef()
    const [isBottomNavShowing, showBottomNavBasedOn] = useState(false)

    const [itemToBoldInBottomNav, setBoldedItemInBottomNavBasedOn] = useState(null)

    useEffect(() => {
        const stickyBottomNav = stickyBottomNavRef.current
        const stickyBottomNavHeight = stickyBottomNav.offsetHeight;

        const whatDoHeader = whatWeDoHeaderRef.current

        const philosophiesHeader = philosophiesHeaderRef.current

        const designHeader = designHeaderRef.current

        const codeHeader = codeHeaderRef.current

        const contactHeader = contactHeaderRef.current

        const onScrollFunction = () => {

            showBottomNavBasedOn(_ => stickyBottomNavLogic())
            
            showWhatWeDoHeaderBasedOn(_ => whatWeDoHeaderLogic())
            
            showPhilosophiesHeaderBasedOn(_ => philosophiesHeaderLogic())

            showDesignHeaderBasedOn(_ => designHeaderLogic())

            showCodeHeaderBasedOn(_ => codeHeaderLogic())

            showContactHeaderBasedOn(_ => contactHeaderLogic())
        }
        window.addEventListener("scroll", onScrollFunction)
        
        const stickyBottomNavLogic = () => {
            const showWhenScrolledAtLeast = 75;
                    
            if (window.scrollY >= showWhenScrolledAtLeast) return true;
            
            return false
        }
        
        const whatWeDoHeaderLogic = () => {
            
            const fromTopToH1distance = whatDoHeader.getBoundingClientRect().top
            const headerHeight = whatDoHeader.offsetHeight;
            
            const whatWeDoBottomNav = document.getElementById("bottom-1")
            
            const leeway = 0
            
            const heightWhereToShowHeader = ((window.innerHeight - stickyBottomNavHeight) - headerHeight) - leeway;
            
            const headerHasPassedStickyBottomNav = fromTopToH1distance <= heightWhereToShowHeader
        
            if  (headerHasPassedStickyBottomNav && fromTopToH1distance >= 0) {
                setBoldedItemInBottomNavBasedOn(_ => whatWeDoBottomNav)
                return true;
                
            }
            setBoldedItemInBottomNavBasedOn(_ => null)
            
            return false;
        
        }
        
        const philosophiesHeaderLogic = () => {
            
            const headerHeight = philosophiesHeader.offsetHeight
            const fromTopToH1distance = philosophiesHeader.getBoundingClientRect().top
        
            const philosophiesBottomNav = document.getElementById("bottom-2")
            
            const leeway = 0
        
            const heightWhereToShowHeader = ((window.innerHeight - stickyBottomNavHeight) - headerHeight) - leeway;
        
            const headerHasPassedStickyBottomNav = fromTopToH1distance <= heightWhereToShowHeader
            
        
            if  (headerHasPassedStickyBottomNav && fromTopToH1distance >= 0) {
                setBoldedItemInBottomNavBasedOn(_ => philosophiesBottomNav)
                return true; 
            }

            return false
        }
        
        const designHeaderLogic = () => {
    
            const philosophiesPaddingHeight = window.getComputedStyle(philosophiesHeader).paddingTop
        
            const philosophiesHeaderHeight = philosophiesHeader.offsetHeight - parseFloat(philosophiesPaddingHeight);
        
            const fromTopToH1distance = designHeader.getBoundingClientRect().top
        
            const designBottomNavItem = document.getElementById("bottom-2.1")
            
            const leeway = 0
        
            const heightWhereToShowHeader = ((window.innerHeight - stickyBottomNavHeight) - philosophiesHeaderHeight) - leeway;
        
            const headerHasPassedStickyBottomNav = fromTopToH1distance <= heightWhereToShowHeader
        
            if  (headerHasPassedStickyBottomNav && fromTopToH1distance >= philosophiesHeaderHeight) {
                setBoldedItemInBottomNavBasedOn(_ => designBottomNavItem)
                return true;
            }
        
            return false;
        }
    
        const codeHeaderLogic = () => {
    
            const philosophiesPaddingHeight = window.getComputedStyle(philosophiesHeader).paddingTop
            const philosophiesHeaderHeight = philosophiesHeader.offsetHeight - parseFloat(philosophiesPaddingHeight);

            const fromTopToH1distance = codeHeader.getBoundingClientRect().top
            
            const codeBottomNavItem = document.getElementById("bottom-2.2")

            const leeway = 0

            const heightWhereToShowHeader = ((window.innerHeight - stickyBottomNavHeight) - philosophiesHeaderHeight) - leeway;

            const headerHasPassedStickyBottomNav = fromTopToH1distance <= heightWhereToShowHeader
            

            if  (headerHasPassedStickyBottomNav && fromTopToH1distance >= philosophiesHeaderHeight) {
                setBoldedItemInBottomNavBasedOn(_ => codeBottomNavItem)
                return true;
            }

            return false;
    
        }
    
        const contactHeaderLogic = () => {
    
            const headerHeight = contactHeader.offsetHeight;

            const fromTopToH1distance = contactHeader.getBoundingClientRect().top
            
            const contactBottomNavItem = document.getElementById("bottom-3")

            const leeway = 0

            const heightWhereToShowHeader = ((window.innerHeight - stickyBottomNavHeight) - headerHeight) - leeway;

            const headerHasPassedStickyBottomNav = fromTopToH1distance <= heightWhereToShowHeader
        

            if  (headerHasPassedStickyBottomNav && fromTopToH1distance >= 0) {
                setBoldedItemInBottomNavBasedOn(_ => contactBottomNavItem)
                return true; 
            }

            return false;  
        }

        return function cleanup() {
            window.removeEventListener("scroll", onScrollFunction)
        }
        
    })

    return (
        <div className="h-full">
            
            <div className="h-screen">
            <Intro></Intro>
                
            </div>

            <div className="2xl:ml-64 xl:ml-52 xl:mr-0 lg:ml-28 lg:mr-24 md:ml-8 md:mr-4 sm:ml-4 mr-0 h-full">
        
                <WhatWeDo ref={whatWeDoHeaderRef} showHeader={isWhatWeDoHeaderShowing}></WhatWeDo>

                <Philosophies ref={philosophiesHeaderRef} showHeader={isPhilosophiesHeaderShowing}>

                    <Design ref={designHeaderRef} showHeader={isDesignHeaderShowing}></Design>

                    <Code ref={codeHeaderRef} showHeader={isCodeHeaderShowing}></Code>

                </Philosophies>

                <Contact ref={contactHeaderRef} showHeader={isContactHeaderShowing}></Contact>

                <StickyBottomNav ref={stickyBottomNavRef} showBottomNav={isBottomNavShowing} menuItemToBold={itemToBoldInBottomNav}/>

            </div>
        </div>
    )
}

export default Home;