import { animated, useSpring } from "react-spring";
import { arriveSlowlyFromBottomToTop, arriveSlowlyFromTopToBottom } from "../../animations/animations";
import { useTranslation } from "react-i18next";
import ContactForm from "./components/contact-form.component";

const ContactUs = () => {
    const { t } = useTranslation()
    const _arriveSlowlyFromBottomToTop = useSpring(arriveSlowlyFromBottomToTop)
    const _arriveSlowlyFromTopToBottom = useSpring(arriveSlowlyFromTopToBottom)

    return (
        <div className="flex flex-col justify-center items-center sm:pt-12 py-6 w-fit mx-auto px-3">
            <animated.h1 style={_arriveSlowlyFromTopToBottom} className="alegreya-sans md:text-6xl sm:text-5xl text-4xl font-light leading-none text-center">
                {t("contact-us.header.0")}
                <span className="italic font-normal">{t("contact-us.header.1")}</span>
                {t("contact-us.header.2")}
            </animated.h1>

            <animated.p style={_arriveSlowlyFromTopToBottom} className="alegreya md:text-2xl sm:text-xl text-lg text-center my-2">
                {t("contact-us.p")}
            </animated.p>

            <br />
            
            <ContactForm/>

            <animated.h1 style={_arriveSlowlyFromBottomToTop} className="alegreya-sans md:text-6xl sm:text-5xl text-4xl font-light leading-none text-center sm:pt-16 pt-12 tracking-tight">
                {t("contact-us.bottom-header")}
            </animated.h1>

            <br />

            <animated.p style={_arriveSlowlyFromBottomToTop} className="alegreya md:text-2xl sm:text-xl text-lg text-center">
                <span>
                    {t("contact-us.bottom-p.0")}
                </span>
                <a href={`mailto:${t("email")}`} className="link syne px-1 whitespace-nowrap">{t("email")}</a>
            </animated.p>

            <br />
            <br />
        </div>
    )
}

export default ContactUs