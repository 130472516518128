import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { pages } from "../nav-bar/nav-bar.component";

const Contact = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isHeaderShowing = props.showHeader;

    return (
        <div id="contact" className="flex sm:flex-row flex-col mt-40 relative h-screen 2xl:max-w-screen-2xl max-w-screen-xl sm:items-stretch items-center">
            
            <div className="2xl:w-[42.5%] xl:w-1/3 lg:w-2/4 md:w-3/5 sm:w-1/3 sticky top-0 sm:pt-12 pt-0 h-fit backdrop-blur-sm bg-transparent z-10 sm:pr-0 sm:text-start text-center sm:shadow-none shadow-md w-full sm:pb-0 pb-3">
                <h1 ref={ref}
                className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 translate-y-4 pointer-events-none'} 2xl:text-6xl md:text-4xl lg:text-5xl text-3xl md:font-normal sm:font-medium alegreya-sans transition-all ease-in duration-200 pr-2 sm:w-min`}
                id="h1-what-we-do">
                    {t("home.contact.header")}
                </h1>
            </div>

            <div className="w-[1px] shadow-sm bg-[hsla(291,88%,10%,0.10)] md:mr-6 mr-3"/>

            <div className={`${isHeaderShowing ? "opacity-100 blur-0" : "blur-sm opacity-0 -translate-x-14"} alegreya w-full sm:pt-64 pt-16 transition-all duration-200 ease-in lg:text-xl md:text-lg text-base sm:mr-4 sm:px-0 px-5 max-w-screen-sm`}>

                <p>
                    {t("home.contact.p1.0")}
                    <span className="italic"> {t("home.contact.p1.1")} </span>
                    {t("home.contact.p1.2")}
                </p>

                <br />

                <p>{t("home.contact.p2")}</p>
                
                <br />
                <br />

                <NavLink to={pages.contact}>
                    <span className="link text-xl syne py-1">{t("home.contact.button")}</span>
                </NavLink>

            </div>


        </div>
    )
})

export default Contact;