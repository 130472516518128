import { HashLink } from "react-router-hash-link";
import GradientEllipseIndicator from "../../components/gradient-ellipse/gradient-ellipse-indicator.component";
import { animated, useSpring } from "react-spring";
import { arriveSlowlyFromBottomToTop, arriveSlowlyFromTopToBottom } from "../../animations/animations";
import { useTranslation } from "react-i18next";

const CaseStudies = () => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col items-center sm:pt-32 pt-16 mx-3 h-screen">
            <animated.h1 style={useSpring(arriveSlowlyFromTopToBottom)} className="alegreya-sans font-medium lg:text-4xl md:text-3xl text-2xl leading-none text-center">
                {t("case-studies.header")}
            </animated.h1>

            <br />

            <animated.p style={useSpring(arriveSlowlyFromBottomToTop)} className="alegreya md:text-xl text-base text-center">
                {t("case-studies.p.0")}
                <HashLink to="/#what-we-do" className="link italic" preventScrollReset={true}>
                    {t("case-studies.p.1")}
                </HashLink>
                {t("case-studies.p.2")}
            </animated.p>

            <div className="pt-28">
                <GradientEllipseIndicator/>
            </div>
        </div>
    )
}

export default CaseStudies;