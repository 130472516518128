const DesignCard = (props) => {
    return (
        <div className="flex justify-between items-center rounded-md almost-white py-6 min-w-fit">

            <div className="pl-0.5 min-w-fit">
                <div className="flex items-center pb-1 pr-2">
                    <img className="md:w-24 w-16 select-none" src={props.image} alt="People centered" />
                    <h1 className="font-bold md:text-xl text-lg gray-to-black-smaller-text alegreya-sans">{props.title}</h1>
                </div>
                <p className="lg:text-xl md:text-lg text-base pl-6 box-decoration-clone alegreya pr-3">{props.body}</p>
            </div>

            {/* <div className="min-w-fit">
                <img src={props.image} alt="People centered" />
            </div> */}

        </div>
    )
}

export default DesignCard;