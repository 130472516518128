import "./intro.styles.css";

import NineLivesLogo from "./9lives_logo.svg"
import HusLogo from "./hus_logo.svg"
import CoolerVectorLine from "./cooler-vector-line.svg"
import EllipseForMobile from "./ellipse_for_mobile.svg"
import { useSpring, animated, config } from "react-spring";
import { useState } from "react";
import { arriveSlowlyFromBottomToTop, arriveSlowlyFromTopToBottom, fadeInAndScaleIn } from "../../animations/animations";
import { useTranslation } from "react-i18next";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

const Intro = () => {
    const { t } = useTranslation()

    const [flip, set] = useState(false)
    const nudgeLoopVertically = useSpring({
        to: { 
            transform: "translateY(3px)",
        }, 
        from: { 
            transform: "translateY(-1px)",
        },
        delay: 600,
        reverse: flip,
        onRest: () => set(!flip),
        config: config.gentle,
    })

    return (
        <div id="intro" className="flex flex-col justify-between items-center md:pt-24 sm:pt-16 pt-12 text-center relative h-[88%]">

            <div className="absolute bottom-24 w-full select-none pointer-events-none overflow-hidden h-72 sm:flex hidden">
                <div className="top-0 right-0 bottom-0 absolute vector-line-overlay z-10 bg-color"/>
                <img className="left-0 w-full vector-line" src={CoolerVectorLine} alt="" />
            </div>
            
            <div className="absolute bottom-24 w-full select-none pointer-events-none overflow-hidden h-72 sm:hidden flex">
                <div className="top-0 right-0 bottom-0 absolute vector-line-overlay z-10 bg-color"/>
                <img className="scale-y-150" src={CoolerVectorLine} alt="" />
            </div>

            <div className="flex flex-col items-center w-full">
                <div>
                    <animated.h1 className="alegreya-sans 2xl:text-6xl lg:text-5xl md:text-4xl text-3xl leading-none testing" style={useSpring(arriveSlowlyFromTopToBottom)}>
                         {t("home.intro.header.0")} 
                        <span className="brand-gradient 2xl:text-7xl lg:text-6xl md:text-5xl text-4xl syne tracking-tight border rounded-lg"> {t("home.intro.header.1")} </span>
                        { t("home.intro.header.2") }
                        <span className="brand-gradient-flipped 2xl:text-7xl lg:text-6xl md:text-5xl text-4xl syne tracking-tight"> { t("home.intro.header.3") }</span>
                        { t("home.intro.header.4") }
                    </animated.h1>
                </div>

                <div className="alegreya 2xl:text-3xl lg:text-2xl md:text-xl text-lg mt-6 text-[#1C0221] px-3">
                    <animated.p style={useSpring(arriveSlowlyFromTopToBottom)}>
                        { t("home.intro.p.0") }
                        <span className="italic font-medium"> { t("home.intro.p.1") }</span>
                    </animated.p>
                </div>

                <div className="mt-14 flex-col syne md:text-xl text-lg justify-center items-center sm:flex hidden">
                    <animated.a style={useSpring(fadeInAndScaleIn)} href="#what-we-do" className={`secondary-button w-fit pl-2.5 pr-1.5 py-1 syne button-box-shadow active:translate-y-0 active:translate-x-0 active:shadow-none transition-all duration-75 hover:translate-x-1 hover:-translate-y-1 group sm:block hidden`}>
                        <span className="brand-gradient font-medium tracking-wide group-hover:brightness-125 uppercase">
                        {t("home.intro.button")}
                        </span>
                        
                        <animated.span style={nudgeLoopVertically} className="inline-block relative -right-0.5">
                            <ArrowDownwardOutlinedIcon className="brand-gradient-flipped"/>
                        </animated.span>
                    </animated.a>
                </div>

                <animated.div style={useSpring(fadeInAndScaleIn)} className="mt-14 flex flex-col syne text-lg justify-center items-center gap-2.5 sm:hidden">    
                    <animated.a href="#what-we-do" className={`secondary-button w-fit pl-2.5 pr-1.5 py-1 syne text-lg introduction-btn-shadow active:translate-x-1 active:-translate-y-1 transition-all duration-75 group`}>
                        <span className="brand-gradient font-medium tracking-wide group-hover:brightness-125 ease-out">
                            {t("home.intro.button")}
                        </span>
                        <animated.span style={nudgeLoopVertically} className="inline-block relative -right-0.5">
                            <ArrowDownwardOutlinedIcon className="brand-gradient-flipped"/>
                        </animated.span>
                    </animated.a>
                </animated.div>
            </div>

            <animated.div style={useSpring(arriveSlowlyFromBottomToTop)} className="syne tracking-wide 2xl:text-lg sm:text-base text-xs w-full z-10">


                <div className="flex justify-center gap-14 items-center pb-2">

                    {/* <a href="https://9lives.fi/" className="2xl:w-28 sm:w-20 w-16" target="__blank">
                    <img src={NineLivesLogo} alt="9Lives" className="hover:scale-105 transition-all"/>
                    </a> */}


                    {/* <a href="https://www.hus.fi/" className="2xl:w-28 sm:w-20 w-16" target="__blank">
                        <img src={HusLogo} alt="HUS" className="hover:scale-105 transition-all"/>
                    </a> */}

                </div>

                {/* <p className="block py-1 tracking-wide opacity-60">ASIAKKAAMME</p> */}
            </animated.div>


        </div>
    )
}

export default Intro