import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { NavLink, useLocation } from "react-router-dom"
import CodebasedLogo from "../codebased-logo/codebased-logo.component"
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';

import "./nav-bar.styles.css"

export const pages = {
    home: "/",
    caseStudies: "/case-studies",
    about: "/about",
    contact: "/contact"
}

const NavBar = () => {
    const { t } = useTranslation()

    const [isRendered, setToRendered] = useState(false)
    const [isMenuShowing, showMenu] = useState(false)

    const menuContentWrapper = useRef(null)

    const firstNavLink = useRef(null) 
    const secondNavLink = useRef(null) 
    const thirdNavLink = useRef(null) 
    
    const indicatorLine = useRef(null)

    const location = useLocation().pathname;

    const activeStyles = {
        pointerEvents: "none",
        opacity: "1",
    }
    
    const notActiveStyles = {
        opacity: "0.7",
    }

    const mobileViewActiveStyles = {
        borderRight: "10px solid var(--almostwhite)",
    }

    const mobileViewNotActiveStyles = {
        opacity: "0.7",
        borderRight: "0px solid white",
    }

    useEffect(() => {
        setToRendered(_ => true);
        
        const closeMenuWhenClickedAway = (e) => {
            const menuContentsWrapperID = "menu-content"
            const menuBtnID = "menu-btn";
    
            const targetID = e.target.id
            const targetParentID = e.target.parentNode?.id
    
    
    
            if (targetParentID !== menuContentsWrapperID && targetID !== menuBtnID) {
                closeMenu()
                e.stopPropagation();
            }
    
        }
        document.addEventListener("click", closeMenuWhenClickedAway)

        return function cleanup() {
            document.removeEventListener("click", closeMenuWhenClickedAway)
        }
    }, [])

    const closeMenu = () => {
        showMenu(_ => false);
    }

    const calculateIndicatorLineStyles = (location) => {
        if (!isRendered) return;

        if (location === pages.home) {
            const width = firstNavLink.current.offsetWidth
                
            indicatorLine.current.style = `width: ${width}px`
            return "left-0";

        } else if (location === pages.caseStudies) {
            const width = secondNavLink.current.offsetWidth
                
            indicatorLine.current.style = `width: ${width}px`
            return "left-1/2 -translate-x-1/2 -ml-4"
        }  else if (location === pages.about) {
            const width = thirdNavLink.current.offsetWidth
            indicatorLine.current.style = `width: ${width}px`
        } else {
            const width = 0
            indicatorLine.current.style = `width: ${width}px`
        }

        return "left-full -translate-x-full"
    }

    const delegateShowMenu = () => {
        showMenu(prevValue => !prevValue)
    }

    return (
        <div className="flex flex-row nav-bar-boxshadow bg-color sticky top-0
         rounded-md z-20 xl:w-2/3 lg:w-3/4 sm:w-11/12 w-[92.5%] mx-auto min-w-fit">

            <div className="flex flex-row items-center rounded-r-md flex-1 sm:justify-between 2xl:text-xl lg:text-lg text-base relative py-3.5 sm:pl-3 pl-1.5 pr-3">

                <button id="menu-btn" type="button" onClick={delegateShowMenu} className="hamburger cursor-pointer h-10 w-10 justify-center items-center relative sm:hidden flex mr-2">
                        <span className={`${isMenuShowing ? 'top-1/2 -translate-y-1/2 -rotate-45 scale-125' : 'top-2.5 scale-100'} w-6 h-1 bg-[color:var(--darksienna)] rounded-lg absolute transition-all pointer-events-none duration-200 ease-out`}></span>
                        <span className={`${isMenuShowing ? 'scale-x-0 opacity-0' : 'scale-x-100 opacity-100'} w-6 h-1 bg-[color:var(--darksienna)] rounded-lg absolute transition-all ease-in-out pointer-events-none duration-500`}></span> 
                        <span className={`${isMenuShowing ? 'top-1/2 -translate-y-1/2 rotate-45 scale-125' : 'bottom-2.5 scale-100'} w-6 h-1 bg-[color:var(--darksienna)] rounded-lg absolute transition-all ease-out pointer-events-none duration-200`}></span> 
                </button>

                <div className="mr-3">
                    <CodebasedLogo className="md:h-7 h-5"/>
                </div>
                
                <div className="flex items-center md:gap-6 sm:gap-1 gap-6 ml-auto">
                    <nav className="syne flex-col relative sm:flex hidden whitespace-nowrap">
                        <div>
                            <NavLink to={pages.home} end className="tracking-wider transition-all hover:[color:var(--smokyblack)] hover:opacity-100 py-2 relative" style={({isActive}) => isActive ? activeStyles : notActiveStyles} ref={firstNavLink}>
                                {t("top-nav-bar.0")}
                            </NavLink>
                            
                            <span className="select-none">
                                &thinsp;
                                &middot;
                                &thinsp;
                            </span>

                            <NavLink to={pages.caseStudies} className="tracking-wider transition-all hover:[color:var(--smokyblack)] hover:opacity-100 py-2 relative" style={({isActive}) => isActive ? activeStyles : notActiveStyles} ref={secondNavLink}>
                                {t("top-nav-bar.1")}
                            </NavLink>

                            <span className="select-none">
                                &thinsp;
                                &middot;
                                &thinsp;
                            </span>
                            
                            <NavLink to={pages.about} className="tracking-wider transition-all hover:[color:var(--smokyblack)] hover:opacity-100 py-2 relative" style={({isActive}) => isActive ? activeStyles : notActiveStyles} ref={thirdNavLink}>
                                {t("top-nav-bar.2")}
                            </NavLink>

                        </div>
                        <div className="h-1 absolute rounded-sm left-0 right-0 -bottom-0.5">
                            <div className={`${calculateIndicatorLineStyles(location)} bg-[#3d050f80] absolute h-full rounded-sm transition-all duration-200 ease-in-out`} ref={indicatorLine}/>
                        </div>
                    </nav>
                        
                    <NavLink to={pages.contact}>
                        <button type="button" className="primary-button py-2 px-2.5 syne button-box-shadow active:shadow-none hover:translate-x-1 hover:-translate-y-1 tracking-wider ease-in transition-all duration-75 active:translate-x-0 active:translate-y-0 almost-white-text hover:brightness-110 uppercase sm:text-base text-sm">
                            {t("top-nav-bar.3")}
                        </button>
                    </NavLink>
                </div>

                <nav id="menu-content" className={`${isMenuShowing ? 'opacity-100 pointer-events-auto blur-0' : 'opacity-0 pointer-events-none blur-md'} flex flex-col absolute translate-y-full bg-[#290330] right-0 bottom-0 w-full sm:hidden text-end syne rounded-md z-50 shadow-lg overflow-hidden transition-all`} ref={menuContentWrapper}>
                    <NavLink to={pages.home} end className="tracking-wider transition-all py-3 almost-white-text pr-2 justify-end items-center" style={({isActive}) => isActive ? mobileViewActiveStyles : mobileViewNotActiveStyles}>
                        {t("top-nav-bar.0")}
                        <HomeOutlinedIcon className="ml-0.5"/>
                    </NavLink>
                    <NavLink to={pages.caseStudies} className="tracking-wider transition-all py-3 almost-white-text pr-2" style={({isActive}) => isActive ? mobileViewActiveStyles : mobileViewNotActiveStyles}>
                        {t("top-nav-bar.1")}
                        <LocalLibraryOutlinedIcon className="ml-1"/>
                    </NavLink>
                    <NavLink to={pages.about} className="tracking-wider transition-all py-3 almost-white-text pr-2" style={({isActive}) => isActive ? mobileViewActiveStyles : mobileViewNotActiveStyles}>
                        {t("top-nav-bar.2")}
                        <ContactMailOutlinedIcon className="ml-2"/>
                    </NavLink>
                </nav>
            </div>
        </div>
    )
}

export default NavBar