import React from "react";
import { useTranslation } from "react-i18next";

const Philosophies = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isHeaderShowing = props.showHeader;

    return (
        <div id="philosophies" className="flex flex-col mt-72 relative 2xl:max-w-screen-2xl max-w-screen-xl w-full">
            
            <div className="sticky top-0 sm:pt-12 pt-3 h-fit bg-transparent z-20 sm:pr-0 sm:w-min w-fit self-start sm:pl-0 pl-3 backdrop-blur-sm">
                <h1 id="h1-our-philosophies" ref={ref}
                className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'opacity-0 translate-y-4 pointer-events-none blur-sm'} 2xl:text-6xl md:text-4xl lg:text-5xl text-3xl sm:font-normal font-medium alegreya-sans transition-all ease-in duration-200`}>
                    {t("home.our-philosophies.header")}
                </h1>
            </div>
            

            <div className="alegreya flex mb-72 pt-12">

                <div className="w-1/3 relative"/>

                <div className="w-[1px] shadow-sm bg-[hsla(291,88%,10%,0.10)] mr-6"/>


                {/* <div className={`${isHeaderShowing ? 'opacity-100' : 'opacity-0 -translate-x-14'} transition-all duration-500 ease-in-out w-[62%] text-2xl`}>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi recusandae voluptate cupiditate dolor ut commodi nemo ipsam totam quisquam voluptatibus?</p>

                    <br />

                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veritatis.</p>

                    <br />

                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi possimus repellat temporibus dolores. Dolor totam alias excepturi ab animi nam, a, voluptate perspiciatis odit accusamus molestiae consequatur aperiam quo labore eaque! Et sequi dolorum tempore.</p>
                </div>

                <br />
                <br /> */}
            </div>


            {props.children}

            
        </div>
    )
})

export default Philosophies;