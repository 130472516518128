import { Outlet, ScrollRestoration } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/footer.component";
import NavBar from "./components/nav-bar/nav-bar.component";

function App() {
  return (
    <div className="App h-screen bg-color pt-3">
      <NavBar />

      <div>
        <Outlet />
        <Footer />
      </div>
      <ScrollRestoration></ScrollRestoration>
    </div>
  );
}

export default App;
