import BrandGradientEllipse from "./brand-gradient-ellipse.svg"

import { animated, useSpring, config } from "react-spring"
import { useState } from "react"
import { fadeInAfterDelay } from "../../animations/animations"

const GradientEllipseIndicator = () => {

    const [flip, set] = useState(false)
    const topEllipse = useSpring({
        to: { 
            transform: "scale(1)",
            opacity: 1,
        }, 
        from: {
            transform: "scale(0)",
            opacity: 0,
        },
        delay: 1000,
        reverse: flip,
        onRest: () => set(!flip),
        config: config.molasses,
    })

    const middleEllipse = useSpring({
        to: {
            transform: "scale(1)",
        }, 
        from: { 
            transform: "scale(3)",
        },
        delay: 1000,
        reverse: flip,
        onRest: () => set(!flip),
        config: config.molasses,
    })

    const bottomEllipse = useSpring({
        to: {
            transform: "scale(1)",
            opacity: 1,
        }, 
        from: { 
            transform: "scale(0)",
            opacity: 0,
        },
        delay: 1000,
        reverse: flip,
        onRest: () => set(!flip),
        config: config.molasses,
    })

    return (
        <animated.div style={useSpring(fadeInAfterDelay)} className="absolute left-1/2 -translate-x-1/2">
            <animated.img style={topEllipse} className="md:w-8 w-4 pointer-events-none rounded-full" src={BrandGradientEllipse} alt="" />
            <animated.img style={middleEllipse} className="md:w-8 w-4 pointer-events-none rounded-full animate-pulse z-10" src={BrandGradientEllipse} alt="" />
            <animated.img style={bottomEllipse} className="md:w-8 w-4 pointer-events-none rounded-full" src={BrandGradientEllipse} alt="" />
        </animated.div>
    )
}

export default GradientEllipseIndicator;