import { useTranslation } from "react-i18next";
import CodebasedLogo from "../codebased-logo/codebased-logo.component";

const Footer = () => {
    const { t } = useTranslation()

    return (
    <footer className="w-full h-1/3 bg-[color:var(--smokyblack)] pt-12 pb-6 z-[99999999] relative bottom-0">
        <div className="sm:w-4/5 w-11/12 h-full mx-auto syne lining-nums sm:text-lg">
            <div className="w-full flex flex-col justify-between h-full">
                <div className="md:pb-3.5 sm:pb-2.5 pb-2">
                    <CodebasedLogo className="md:h-12 sm:h-10 h-8" isWhite={true}/>
                </div>
                <div>
                    <p className="text-[color:var(--almostwhite)]">Y-tunnus: 3278467-1</p>
                    <p className="text-[color:var(--almostwhite)]">Rovaniemi, Lappi (toimialueena koko universumi)</p>
                    <p className="text-[color:var(--almostwhite)] pb-0.5">62010 Ohjelmistojen suunnittelu ja valmistus</p>
                    <a href={`mailto:${t("email")}`} className="footer-link syne whitespace-nowrap text-[color:var(--almostwhite)] sm:text-xl pr-1 sm:py-0.5 py-1.5">📮 {t("email")}</a>
                </div>
                <div className="pt-12">
                    <div className="w-full h-[1px] shadow-sm bg-[color:var(--almostwhite)] md:mr-6 mr-3 opacity-25 my-6"/>
                    <p className="text-[color:var(--almostwhite)]">Copyright © 2022 Codebased Oy</p>
                </div>
            </div>
        </div>
    </footer>
    )
}

export default Footer;