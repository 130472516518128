import { Formik, Form, Field, ErrorMessage } from "formik"
import LoadingIndicator from "../icons/loading_indicator.svg"
import CheckmarkBg from "../icons/checkmark_bg.svg"
import Checkmark from "../icons/checkmark.svg"
import { useEffect, useState } from "react"
import { useSpring, animated } from "react-spring"
import { arriveSlowlyFromBottomToTop, fadeInAfterDelay } from "../../../animations/animations"
import { useTranslation } from "react-i18next"
import { send } from "@emailjs/browser"
import { useLocation } from "react-router-dom"

const ContactForm = () => {
    const { t } = useTranslation()
    const location = useLocation();

    const [wasMailSent, setMailStatusToSent] = useState(false)
    const _fadeInAfterDelay = useSpring(fadeInAfterDelay)
    const _arriveSlowlyFromBottomToTop = useSpring(arriveSlowlyFromBottomToTop)

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

    const formValues = {
        fullName: localStorage.getItem("fullName") ?? "",
        company: localStorage.getItem("company") ?? "",
        email: localStorage.getItem("email") ?? "",
        description: localStorage.getItem("description") ?? "",
    }
    
    useEffect(() => {
        return function cleanup () {
            saveFilledFormToLocalStorage()
        }
    }, [location])


    const sendInquiry = async (values, { setSubmitting, resetForm, setErrors}) => {
        try {
            await send(serviceID, templateID, {
                from_name: values.fullName,
                from_company: values.company,
                message: values.description,
                from_email: values.email,
            }, publicKey)
            
            setMailStatusToSent(true)
            
            setTimeout(() => {
                setSubmitting(false)
                setMailStatusToSent(false)
                resetForm()
                deleteFilledFormValuesFromLocalStorage()
            }, 10000)
            
        } catch (error) {
            setErrors({
                "form": t("contact-us.form-submit-error")
            })
            setSubmitting(false)
        }
    }

    const deleteFilledFormValuesFromLocalStorage = () => {
        localStorage.removeItem("fullName")
        localStorage.removeItem("company")
        localStorage.removeItem("email")
        localStorage.removeItem("description")
    }

    const validateForm = (currentFormValues) => {
        const errors = {};

        saveFilledFormToLocalStorage(currentFormValues)

        if (!currentFormValues.fullName.length) {
            errors.fullName = t("contact-us.fullName.error")
        }

        if (!currentFormValues.company) {
            errors.company = t("contact-us.company.error")
        }
        
        if (!currentFormValues.email) {
            errors.email = t("contact-us.email.error-required")
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(currentFormValues.email)) {
            errors.email = t("contact-us.email.error-misinput")
        }


        if (!currentFormValues.description) {
            errors.description = t("contact-us.description.error-required")
        } else if (currentFormValues.description.length < 40) {
            errors.description = t("contact-us.description.error-short")
        }

        return errors
    }

    const saveFilledFormToLocalStorage = (formValues) => {
        if (formValues) {
            localStorage.setItem("fullName", formValues?.fullName)
            localStorage.setItem("company", formValues?.company)
            localStorage.setItem("email", formValues?.email)
            localStorage.setItem("description", formValues?.description)
        }
    }


    return (
        <Formik initialValues={formValues}
                validate={validateForm}
                onSubmit={sendInquiry}>
                    {({
                    errors,
                    touched,
                    isSubmitting,
                    isValid,
                    values,
                }) => (
                    <Form className="flex flex-col alegreya-sans sm:text-2xl text-lg gap-4 items-stretch justify-center w-full px-6 relative">
                        <animated.div style={_arriveSlowlyFromBottomToTop} className="flex justify-between items-center gap-6 w-full">
                            <div className="flex flex-col w-1/2 relative">
                                <label htmlFor="fullName" className="font-medium pb-0.5">{t("contact-us.fullName.label")}</label>
                                <Field id="fullName" type="text" name="fullName" className={`${errors.fullName && touched.fullName ? 'border-red-600' : ''} border rounded-sm outline-none border-slate-40 shadow-md almost-white px-2.5 py-2 sm:text-xl text-base focus:border-[color:var(--darksienna)] w-full transition-all disabled:opacity-50 disabled:shadow-none`} disabled={isSubmitting || wasMailSent} required/>
                                <div className="h-6">
                                    <ErrorMessage name="fullName" component="p" className="md:text-base text-sm text-red-700 font-bold"/>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/2 relative">
                                <label htmlFor="company" className="font-medium pb-0.5">{t("contact-us.company.label")}</label>
                                <Field id="company" type="text" name="company" className={`${errors.company && touched.company ? 'border-red-600' : ''} border rounded-sm outline-none border-slate-40 shadow-md px-2.5 py-2 sm:text-xl text-base almost-white focus:border-[color:var(--darksienna)] w-full transition-all disabled:opacity-50 disabled:shadow-none`} disabled={isSubmitting || wasMailSent} required/>
                                <div className="h-6">
                                    <ErrorMessage name="company" component="p" className="md:text-base text-sm text-red-700 font-bold"/>
                                </div>
                            </div>
                        </animated.div>
                        <animated.div style={_arriveSlowlyFromBottomToTop} className="flex flex-col relative">
                            <label htmlFor="email" className="font-medium pb-0.5">{t("contact-us.email.label")}</label>
                            <Field id="email" type="email" name="email" className={`${errors.email && touched.email ? 'border-red-600' : ''} shadow-md border rounded-sm outline-none border-slate-30  px-2.5 py-2 sm:text-xl text-base almost-white focus:border-[color:var(--darksienna)] transition-all disabled:opacity-50 disabled:shadow-none`} disabled={isSubmitting || wasMailSent} required/>
                            <div className="h-6">
                                <ErrorMessage name="email" component="p" className="md:text-base text-sm text-red-700 font-bold"/>
                            </div>
                        </animated.div> 
                        
                        <animated.div style={_arriveSlowlyFromBottomToTop} className="flex flex-col relative">
                            <label htmlFor="description" className="font-medium pb-0.5">{t("contact-us.description.label")}</label>
                            <Field id="description" name="description" className={`${errors.description && touched.description ? 'border-red-600' : ''} h-48 max-h-80 placeholder:[font-family:alegreya] px-2.5 py-2 sm:text-xl text-base almost-white border rounded-sm focus:border-[color:var(--darksienna)] shadow-md outline-none transition-all disabled:opacity-50 disabled:shadow-none`} placeholder={t("contact-us.description.placeholder")} as="textarea" disabled={isSubmitting || wasMailSent} required/>
                            <div className="h-6">
                                <ErrorMessage name="description" component="p" className="md:text-base text-sm text-red-700 font-bold"/>
                            </div>
                        </animated.div>

                        <animated.div style={_fadeInAfterDelay} className="relative w-full h-full">
                            <button type="submit" className="disabled:pointer-events-none disabled:grayscale disabled:opacity-25 primary-button py-2 px-2.5 alegreya-sans button-box-shadow active:shadow-none hover:translate-x-1 hover:-translate-y-1 tracking-wider ease-in transition-all duration-75 active:translate-x-0 active:translate-y-0 almost-white-text hover:brightness-110 uppercase text-xl w-full font-medium" disabled={!isValid || isSubmitting || wasMailSent || (!values.fullName || !values.company || !values.email || !values.description)}>
                            {t("contact-us.button")}
                            </button>
                            <img src={LoadingIndicator} alt="" className={`${isSubmitting ? 'opacity-100 scale-100' : 'opacity-0 scale-0'} absolute bottom-0 left-1/2 -translate-x-1/2 h-[inherit] transition-all ease-in-out duration-300`}/>
                            <img src={CheckmarkBg} alt="" className={`${wasMailSent ? "-translate-y-1/2 opacity-100 scale-100" : "opacity-0 -translate-y-6 scale-0"} absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 h-[inherit] transition-all duration-300 ease-in-out`} />
                            <img src={Checkmark} alt="" className={`${wasMailSent ? "-translate-y-1/2 opacity-100" : "opacity-0 -translate-y-6"} absolute left-1/2 -translate-x-1/2 h-1/3 transition-all duration-200 top-1/2  ease-in`} />
                            <p className={`${!isSubmitting && wasMailSent ? "opacity-100" : "opacity-0"} absolute md:text-xl text-base font-bold pt-1 transition-all ease-out duration-300 text-center w-full`}>
                                {t("contact-us.form-submit-success")}
                            </p>
                            <p className="absolute md:text-xl text-base text-red-700 font-bold pt-1">{ errors.form }</p>
                        </animated.div>
                    </Form>
            )}
        </Formik>
    )
}


export default ContactForm