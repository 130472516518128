import React from "react";
import { useTranslation } from "react-i18next";
import useGetScrollDirection from "../../hooks/useGetScrollDirection";
import InHomePage from "./in-home-page/in-home-page.component";
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';

const StickyBottomNav = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isBottomNavShowing= props.showBottomNav;
    const scrollDirection = useGetScrollDirection()

    const menuItemToBold = props.menuItemToBold;
    const menuItemToBoldHref = menuItemToBold?.getAttribute("href");

    const returnToTop = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    const showBackBtn = menuItemToBoldHref !== undefined
    return (
        <div className={`${isBottomNavShowing ? 'translate-y-4 opacity-100' : 'opacity-0 pointer-events-none translate-y-6'} fixed bottom-0 z-20 transition-all ease-in duration-300 bg-transparent w-[inherit] min-w-[150px] md:translate-x-6 sm:translate-x-1.5 translate-x-4`} id="sticky-bottom-nav" ref={ref}>
                <ul className="flex-col gap-2 alegreya lining-nums w-fit min-w-[150px] backdrop-blur-sm h-full pb-20 sm:flex hidden">
                        <button type="button" onClick={returnToTop} className={`${showBackBtn ? 'opacity-100' : 'opacity-0 pointer-events-none translate-y-4'} secondary-button w-fit 2xl:py-1.5 pr-3 pl-2 py-1 alegreya-sans 2xl:text-lg sm:text-base button-box-shadow active:translate-y-0 active:-translate-x-3 active:shadow-none transition-all duration-75 sm:-translate-x-3 md:mb-2 mb-0 hover:-translate-x-2 hover:-translate-y-1 group font-medium`}>
                            <ArrowUpwardOutlinedIcon className="brand-gradient mr-0.5 relative -top-0.5"/>
                            <span className="brand-gradient tracking-wide group-hover:brightness-125 py-0.5">
                                {t("sticky-sub-nav.button")}
                            </span>
                        </button>

                    <div className="2xl:text-lg md:text-base text-xs">
                        <InHomePage itemToBoldHref={menuItemToBoldHref}/>
                    </div>
                </ul>
                <button type="button" onClick={returnToTop} className={`${showBackBtn && scrollDirection === "up" ? 'opacity-100' : 'opacity-0 pointer-events-none translate-y-4'} sm:hidden flex flex-col items-center justify-center mb-10 w-fit alegreya-sans secondary-button pr-3 pl-2 py-1.5 transition-all duration-75 active:translate-x-1 active:-translate-y-1 top-btn-shadow text-sm ease-out font-medium`}>
                    <span className="brand-gradient tracking-wide">
                    <ArrowUpwardOutlinedIcon className="brand-gradient mr-0.5 relative -top-0.5"/>
                        {t("sticky-sub-nav.button")}
                    </span>
                </button>
        </div>
    )
})

export default StickyBottomNav;