import { useEffect, useState } from "react"

function useGetScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null)
    const [lastScrollTop, setLastScrollTop] = useState(null)

    useEffect(() => {
        const scrollPositionCheckerAndSetter = () => {
            const currentScrollPos = window.scrollY || document.documentElement.scrollTop

            if (currentScrollPos > lastScrollTop) {
                setScrollDirection(_ => "down")
            } else {
                setScrollDirection(_ => "up")
            }
            setLastScrollTop((_) => currentScrollPos <= 0 ? 0 : currentScrollPos)
        }
        window.addEventListener("scroll", scrollPositionCheckerAndSetter)

        return function cleanup() {
            window.removeEventListener("scroll", scrollPositionCheckerAndSetter)
        }
    }, [lastScrollTop])

    return scrollDirection;
}

export default useGetScrollDirection

