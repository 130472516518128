import PhilosophyCard from "./design-card.component.jsx";

import UserCenteredLogo from "./user-centered.svg"
import VisuallyDesirableLogo from "./visually-desirable.svg"
import PleasantlyUseful from "./pleasantly-useful.svg"
import ResearchBased from "./research-based.svg"
import React from "react";
import { useTranslation } from "react-i18next";

const philosophyCards = {
    "shiningly-desirable": {
        "title": "Kiiltävästi houkutteleva",
        "body": "Suunnittelemme visuaalisesti esteettisiä käyttöliittymiä, joista voit nauttia. Kaunis typografia ja viehättävät värit valitaan brändiäsi ajatellen.",
        "image": VisuallyDesirableLogo
    },
    "pleasantly-useful": {
        "title": "Miellyttävän hyödyllinen",
        "body": "Design tulee olemaan toiminnallisesti hyödyllinen ja sitä on miellyttävä käyttää.",
        "image": PleasantlyUseful
    },
    "user-centered": {
        "title": "Käyttäjäkeskeinen",
        "body": "Käyttäjät tulevat olemaan mukana koko suunnitteluprosessin ajan. Yrityksessämme kaikki suunnittelu pyörii käyttäjien ympärillä.",
        "image": UserCenteredLogo
    },
    "research-based": {
        "title": "Tutkimuksiin pohjautuva",
        "body": "Yrityksessämme kaikki on oletusta, kunnes toisin todistetaan. Tutkimusten tekeminen sekä niiden lukeminen ovat keinomme vahvistaa oletukset ja varmistaa oikea polku.",
        "image": ResearchBased
    },
}


const Design = React.forwardRef((props, ref) => {
    const { t } = useTranslation()

    const isHeaderShowing = props.showHeader

    return (
            <div id="design" className="text-2xl pt-12 relative top-4 flex sm:flex-row flex-col sm:items-stretch items-center w-full">

                <div className="2xl:w-[42.5%] xl:w-1/3 lg:w-2/4 md:w-3/5 sm:w-1/3 sticky 2xl:pt-44 lg:pt-36 md:pt-32 sm:pt-36 pt-12 top-0 h-fit self-start sm:backdrop-blur-0 backdrop-blur-sm sm:shadow-none shadow-md sm:pb-0 pb-1 w-full overflow-hidden z-10">
                    <h1 id="h1-design" ref={ref}
                     className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 translate-x-8 pointer-events-none'} syne brand-gradient-smaller-text 2xl:text-6xl md:text-4xl lg:text-5xl text-3xl flex-shrink-0 transition-all ease-in duration-200 2xl:ml-12 md:ml-8 sm:ml-4 relative ml-5`}>
                        <div className="absolute codebased-brand-ball md:left-0 -left-1 top-1/2 -translate-y-1/2 rounded-full sm:scale-100 scale-75"></div>
                        <span>  {t("home.our-philosophies.design.header")}</span>
                    </h1>
                </div>

                
                <div className="w-[1px] shadow-sm bg-[hsla(291,88%,10%,0.10)] md:mr-6 mr-3"/>

                <div className={`${isHeaderShowing ? 'opacity-100 blur-0' : 'blur-sm opacity-0 -translate-y-14 -translate-x-14'} transition-all duration-500 ease-in-out flex flex-col gap-8 sm:pt-64 pt-16 w-full pb-12 sm:mr-5 sm:px-0 px-5 max-w-screen-sm`}>

                    <div className="alegreya lg:text-xl md:text-lg text-base">
                        <p>
                            {t("home.our-philosophies.design.p1.0")}
                            <span className="italic"> {t("home.our-philosophies.design.p1.1")}</span>
                        </p>

                        <br />

                        <p className="mt-2.5">
                            {t("home.our-philosophies.design.p2.0")}
                            <span className="italic"> {t("home.our-philosophies.design.p2.1")} </span>
                            {t("home.our-philosophies.design.p2.2")}
                        </p>

                        <br />
                        <br />

                        <p>
                            {t("home.our-philosophies.design.p3")}
                        </p>

                    </div>

                    <div className="flex flex-col gap-14">
                        <PhilosophyCard
                            title={philosophyCards["shiningly-desirable"].title} 
                            body={philosophyCards["shiningly-desirable"].body}
                            image={philosophyCards["shiningly-desirable"].image}>
                            
                        </PhilosophyCard>

                        <PhilosophyCard 
                            title={philosophyCards["pleasantly-useful"].title}
                            body={philosophyCards["pleasantly-useful"].body}
                            image={philosophyCards["pleasantly-useful"].image}>
                        </PhilosophyCard>

                        <PhilosophyCard 
                            title={philosophyCards["user-centered"].title}
                            body={philosophyCards["user-centered"].body} 
                            image={philosophyCards["user-centered"].image}>
                        </PhilosophyCard>

                        <PhilosophyCard 
                            title={philosophyCards["research-based"].title} 
                            body={philosophyCards["research-based"].body}
                            image={philosophyCards["research-based"].image}>

                        </PhilosophyCard>
                    </div>


                    <div className="alegreya lg:text-xl md:text-lg text-base">
                        <p className="lg:text-lg text-base italic tracking-wide text-center">
                            {/* Näiden filosofioiden kautta työstämme designin sinulle. */}
                            {t("home.our-philosophies.design.p4-italic")}
                            <abbr className="alegreya-sc font-bold"> {t("home.our-philosophies.design.p4-italic-abbr")} </abbr> 
                            
                        </p>

                        <br />
                        <br />

                        <p>
                            {t("home.our-philosophies.design.p5.0")}<i> {t("home.our-philosophies.design.p5.1")}</i>
                        </p>

                        <br />

                        <p>
                            {t("home.our-philosophies.design.p6")}
                        </p>

                        <ul className="lining-nums pl-10 list-decimal">
                            <li className="py-0.5 mt-1"> 
                                <b>{t("home.our-philosophies.design.list.first.0")} </b>
                                {t("home.our-philosophies.design.list.first.1")}
                            </li>
                            <li className="py-0.5">
                                <b>{t("home.our-philosophies.design.list.second.0")} </b>
                                {t("home.our-philosophies.design.list.second.1")}
                            </li>
                            <li className="py-0.5">
                                <b>{t("home.our-philosophies.design.list.third.0")} </b>
                                {t("home.our-philosophies.design.list.third.1")}
                            </li>
                        </ul>

                        <br />

                        <p>
                            {t("home.our-philosophies.design.p7")}
                        </p>

                        <br />

                        <p>
                            {t("home.our-philosophies.design.p8")}
                        </p>

                        <br />
                        <br />

                        <p>
                            <i>{t("home.our-philosophies.design.p9.0")} </i>
                            {t("home.our-philosophies.design.p9.1")} <i>{t("home.our-philosophies.design.p9.2")} </i>
                        </p>

                    </div>

                    
                </div>

            </div>
    )
})

export default Design;