const CodeCard = ({title, body, bodyMoreContrast, image}) => {
    return (
        <div className="flex flex-col w-full">

            <div className="self-center md:w-fit w-24">
                <img src={image} alt={title} />
            </div>
            
            <h1 className="font-bold md:text-xl text-lg pb-3 gray-to-black-smaller-text alegreya-sans">
                {title}
            </h1>

            <p className="lg:text-xl md:text-lg text-base alegreya">
                <span className="italic">{bodyMoreContrast}</span> 
                {body}
            </p>

        </div>
    )
}

export default CodeCard;